import axios from "axios"
import { onSuccessResponse, onFailedResponse } from "./onResponseInterceptor"
import { onSuccessRequest, onFailedRequest } from "./onRequestInterceptor"
import { API_URL } from "./baseUrl"

export const USER_LOCAL_STORAGE_KEY = "simsar-website-user"

const instance = axios.create({
  baseURL: `${API_URL}/`,
})
const user = JSON.parse(
  typeof window !== "undefined" && localStorage.getItem(USER_LOCAL_STORAGE_KEY)
)
const access_token =
  user && user.accessToken && user.accessToken !== ""
    ? `Bearer ${user.accessToken}`
    : null

instance.interceptors.response.use(onSuccessResponse, onFailedResponse)
instance.interceptors.request.use(onSuccessRequest, onFailedRequest)
instance.defaults.headers.common["Authorization"] = access_token

export default instance
