import { useState, useEffect } from "react"

const useMediaQuery = query => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    let listener
    const mq = window?.matchMedia(query)
    if (mq) {
      if (mq.matches !== matches) {
        setMatches(mq.matches)
      }

      listener = () => setMatches(mq.matches)

      mq.addEventListener("change", listener)
    }

    return () => {
      mq?.removeEventListener("change", listener)
    }
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  return matches
}

export default useMediaQuery
