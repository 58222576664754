/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import "react-alice-carousel/lib/alice-carousel.css"
import './src/styles/small.scss';
import './src/styles/large.scss';
import './src/styles/xlarge.scss';
import "./src/styles/main.scss"
import "./src/styles/rtl.scss"


import wrapWithProvider from "./src/store/provider-wrapping";
export const wrapRootElement = wrapWithProvider;