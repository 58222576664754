import axios from "axios"
import { onSuccessResponse, onFailedResponse } from "./onResponseInterceptor"
import { onSuccessRequest, onFailedRequest } from "./onRequestInterceptor"
import { API_URL } from "./baseUrl"

const instance = axios.create({
  baseURL: `${API_URL}/`,
})

instance.interceptors.response.use(onSuccessResponse, onFailedResponse)
instance.interceptors.request.use(onSuccessRequest, onFailedRequest)

delete instance.defaults.headers.common.Authorization

export { instance as authInstance }

const authApi = {
  signIn: data => instance.post("auth/login", data),
  signUp: data => instance.post("auth/register", data),
  forgetPassword: data => instance.post("auth/forget-password", data),
  resetPassword: data => instance.post("auth/reset-password", data),
  checkCode: data => instance.post("auth/forget-password/check-code", data),
  confirmEmail: data => instance.get("auth​/confirm-email", data),
}

export default authApi
