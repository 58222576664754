import React from "react"
import { NotificationManager } from "react-notifications"
import api from "../_api/authApi"
import instance, { USER_LOCAL_STORAGE_KEY } from "../_api/instance"
import { navigate } from "gatsby"
import axios from "axios"
import { API_URL } from "../_api/baseUrl"
import { LOGIN_USER, LOGOUT_USER } from "./types"
import { error, success } from "../utils"
import { FormattedMessage } from "react-intl"
import { setLoading } from "./settingsActions"

export const login = data => dispatch => {
  dispatch(setLoading(true))
  return api
    .signIn(data)
    .then(res => {
      if (res) {
        let user = { ...res.user, accessToken: res.token }
        dispatch(getCurrentUser(user))
        dispatch({ type: LOGIN_USER, payload: user })
        success(<FormattedMessage defaultMessage="Signed in successfully" />)
        return Promise.resolve(res)
      } else error()
    })
    .catch(err => {
      error(<FormattedMessage defaultMessage="Email or Password is wrong" />)
      return Promise.reject(err)
    })
    .finally(() => dispatch(setLoading(false)))
}

export const getCurrentUser = user => dispatch => {
  instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.accessToken}`
  return axios
    .get(`${API_URL}me`, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    })
    .then(res => {
      console.log("currentUser", res)
      const updatedUser = { ...res.data, accessToken: user.accessToken }
      dispatch({
        type: LOGIN_USER,
        payload: updatedUser,
      })
      localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(updatedUser))
      return Promise.resolve(res.data)
    })
    .catch(() => {
      dispatch(logoutUser())
      error(<FormattedMessage defaultMessage="Oops session expired!" />)
    })
}

export const signUp = data => dispatch => {
  dispatch(setLoading(true))
  return api
    .signUp(data)
    .then(res => {
      if (res) {
        let user = res.user
        user.accessToken = res.token
        dispatch(getCurrentUser(user))
        success(<FormattedMessage defaultMessage="signed up successfully" />)
        dispatch({ type: LOGIN_USER, payload: user })
        return Promise.resolve(res)
      } else {
        error()
        return Promise.reject()
      }
    })
    .catch(err => {
      error(err?.message?.split("_")?.join(" "))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setLoading(false)))
}

export const logoutUser = () => dispatch => {
  dispatch({ type: LOGOUT_USER })
  localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
  navigate("/", { replace: true })
  success(<FormattedMessage defaultMessage="Logged out successfully" />)
}

export const forgetPassword = data => dispatch => {
  dispatch(setLoading(true))
  return api
    .forgetPassword(data)
    .then(res => {
      if (res) {
        return Promise.resolve(res)
      } else
        NotificationManager.error(
          <FormattedMessage defaultMessage="Oops session expired!" />,
          "",
          3000
        )
    })
    .catch(error => {
      NotificationManager.error("Oops Something went wrong!!", "", 3000)
      return Promise.reject(error)
    })
    .finally(() => dispatch(setLoading(false)))
}

export const resetPassword = data => dispatch => {
  dispatch(setLoading(true))
  return api
    .resetPassword(data)
    .then(res => {
      if (res) {
        NotificationManager.success("Password reset successfully", "", 3000)
        return Promise.resolve(res)
      } else NotificationManager.error("Oops session expired!", "", 3000)
    })
    .catch(() => {
      NotificationManager.error("Oops Something went wrong!!", "", 3000)
      return Promise.reject()
    })
    .finally(() => {
      dispatch(setLoading(false))
      navigate("/")
    })
}

export const verifyCode = data => dispatch => {
  dispatch(setLoading(true))
  return api
    .checkCode(data)
    .then(res => {
      if (res) {
        let user = res.user
        user.accessToken = res.token
        dispatch(getCurrentUser(user))
        dispatch({ type: LOGIN_USER, payload: user })
        success(
          <FormattedMessage defaultMessage="Email confirmed successfully" />
        )
        return Promise.resolve(res)
      } else error()
    })
    .catch(err => {
      error(err?.message?.split("_")?.join(" "))
      return Promise.reject()
    })
    .finally(() => {
      dispatch(setLoading(false))
    })
}
