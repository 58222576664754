import instance from "./instance"

const updateProperty = (id, data) => instance.put(`properties/${id}`, data)
const updateService = (id, data) => instance.put(`services/${id}`, data)
const createProperty = data => instance.post(`properties`, data)
const createService = data => instance.post(`services`, data)

const dataApi = {
  getHomeSlider: () => instance.get(`utils/home-slider`),
  getPropertyTypes: () => instance.get(`property-types`),
  getAmenities: () => instance.get(`amenities`),
  getSizeUnits: () => instance.get(`size-units`),

  createProperty,
  createService,
  updateService,
  updateProperty,
  getServiceCategory: (config = {}) =>
    instance.get("service-category", { ...config }),
  upsertProperty: (id, data) => () =>
    id ? updateProperty(id, data) : createProperty(data),

  getPropertyById: id => instance.get(`properties/${id}`),
  deleteProperty: id => instance.delete(`properties/${id}`),
  deleteService: id => instance.delete(`services/${id}`),
  getProperties: (config = {}) => instance.get("properties", { ...config }),
  getExperts: (config = {}) => instance.get("services", { ...config }),
  getFollowingList: (config = {}) =>
    instance.get("users/followers", { ...config }),
  getSingleService: id => instance.get(`services/${id}`),
  togglePropertyFavorite: id =>
    instance.post(`properties/toggle-favorite/${id}`),
  toggleServiceFavorite: id => instance.post(`services/toggle-favorite/${id}`),
  readNotification: data =>
    instance.post("notification/mark-as-read", data, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  getSingleNotification: id => instance.get(`notification/${id}`),
  postContact: data => instance.post("contact-us", data),
  getConstantPages: id => instance.get(`constant-pages/${id}`),

  upload: (asset, type, config = {}) => {
    const formData = new FormData()
    formData.append("file", asset)
    if (type) {
      formData.append("type", type)
    }
    return instance.post("uploads", formData, {
      ...config,
      headers: { "Content-Type": "multipart/form-data" },
    })
  },
  toggleFollow: (id, config = {}) => {
    let data = null
    if (id) {
      data = { following_id: id }
    }
    return instance.post("users/followers", data, {
      ...config,
    })
  },

  uploadMultiple: (assets, type, config = {}) => {
    const formData = new FormData()
    assets.forEach(asset => {
      formData.append("files[]", asset)
    })
    formData.append("type", type)
    return instance.post("uploads/multiple", formData, {
      ...config,
      headers: { "Content-Type": "multipart/form-data" },
    })
  },
}

export default dataApi
