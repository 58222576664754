import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import reducers from '../reducers';
import logger from 'redux-logger';
import Thunk from 'redux-thunk';


let middleware = [Thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware = [ ...middleware, logger ]
}

const createStore = () => reduxCreateStore(
  reducers,
  compose(applyMiddleware(...middleware))
);

export default createStore;