import React from "react"
import { FormattedMessage } from "react-intl"
import { error, success } from "../utils"
import dataApi from "../_api/dataApi"
import { setLoading } from "./settingsActions"
import {
  SET_HOME_SLIDER,
  SET_PROPERTY_TYPES,
  SET_AMENITIES,
  SET_SIZE_UNITS,
} from "./types"

export const getHomeSliderAction = () => dispatch => {
  dispatch(setLoading(true))
  dataApi
    .getHomeSlider()
    .then(res => {
      if (res) {
        dispatch({ type: SET_HOME_SLIDER, payload: res })
      }
    })
    .finally(() => dispatch(setLoading(false)))
}

export const getPropertyTypesAction = () => dispatch => {
  dispatch(setLoading(true))
  dataApi
    .getPropertyTypes()
    .then(res => {
      if (res) {
        dispatch({ type: SET_PROPERTY_TYPES, payload: res })
      }
    })
    .finally(() => dispatch(setLoading(false)))
}

export const getAmenitiesAction = () => dispatch => {
  dispatch(setLoading(true))
  dataApi
    .getAmenities()
    .then(res => {
      if (res) {
        dispatch({ type: SET_AMENITIES, payload: res })
      }
    })
    .finally(() => dispatch(setLoading(false)))
}

export const getSizeUnitsAction = () => dispatch => {
  dispatch(setLoading(true))
  dataApi
    .getSizeUnits()
    .then(res => {
      if (res) {
        dispatch({ type: SET_SIZE_UNITS, payload: res })
      }
    })
    .finally(() => dispatch(setLoading(false)))
}

export const postContact = data => dispatch => {
  dispatch(setLoading(true))
  return dataApi
    .postContact(data)
    .then(() => {
      success(
        <FormattedMessage defaultMessage="Your message sent successfully" />
      )
    })
    .catch(() => error())
    .finally(() => dispatch(setLoading(false)))
}
