import { TOGGLE_MODAL, TOGGLE_CUSTOM_MODAL } from '../actions/types';

const INIT_STATE = {
  open: false,
  data: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return { ...state, ...action.payload };
    case TOGGLE_CUSTOM_MODAL:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};
