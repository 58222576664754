import {
  GET_MY_FAVORITES, GET_MY_FOLLOWERS,
  GET_MY_PROPERTIES,
  LOGIN_USER,
  LOGOUT_USER,
  GET_MY_NOTIFICATIONS,
} from "../actions/types";
import { USER_LOCAL_STORAGE_KEY } from "../_api/instance"

const INIT_STATE = {
  user: JSON.parse(typeof window !== "undefined" && localStorage.getItem(USER_LOCAL_STORAGE_KEY)),
  myProperties:[],
  myFavorites:[],
  myFollowers:[],
  myNotifications:[],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, user: action.payload };
    case LOGOUT_USER:
      return { ...state, user: null };
    case GET_MY_PROPERTIES:
      return { ...state, myProperties: action.payload };
    case GET_MY_FAVORITES:
      return { ...state, myFavorites: action.payload };
    case GET_MY_FOLLOWERS:
      return { ...state, myFollowers: action.payload };
    case GET_MY_NOTIFICATIONS:
      return { ...state, myNotifications: action.payload };
    default:
      return { ...state };
  }
};
