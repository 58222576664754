import React from "react"
import Modal from "react-modal"
import useForm from "../components/hooksComponents/useForm"
import { toggleCustomModal } from "../actions/settingsActions"
import { connect } from "react-redux"
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai"
import { RiFacebookFill } from "react-icons/ri"
import { FiEdit, FiUser, FiLinkedin } from "react-icons/fi"
import { FormattedMessage } from "react-intl"
import ArrowRight from "../assets/icons/arrowRight"
import OutLineMail from "../assets/icons/outLineMail"
import CloseIcon from "../assets/icons/closeIcon"
import useMediaQuery from "./hooksComponents/useMediaQuery"

const CustomModal = props => {
  const { customOpen, loading, modalType, title } = props
  const close = () => props.toggleCustomModal({ customOpen: false, title: "" })
  const { state, onChange, errors } = useForm({})
  const isMobile = useMediaQuery("(max-width: 1199px)")
  return (
    <Modal
      isOpen={customOpen}
      onRequestClose={close}
      overlayClassName="modalOverlay"
      className={`${modalType === 'report' && isMobile ? "w-80" : "w-50"} p-3 signInModal br16`}
      ariaHideApp={false}
    >
      {modalType === "share" ? (
        <div className="p-lg-5 px-3 py-4">
          <div className="signInBorder d-flex align-items-center justify-content-between">
            <span
              className="font-black black px56 mx-3"
              style={{ fontWeight: "900" }}
            >
              {title}
            </span>
            <div onClick={close} className="pointer">
              <CloseIcon size="1.5rem" style={{ fill: "#616E86" }} />
            </div>
          </div>
          <div className="p-lg-5 p-3">
            <div className="text-uppercase px14 greyLight32 my-3 px-lg-5 p-2">
              <FormattedMessage defaultMessage="Share via" />
            </div>
            <div className="primary d-flex align-items-center justify-content-between px-lg-5 p-2">
              <RiFacebookFill size="2.5rem" />
              <FiLinkedin size="2.5rem" style={{ fill: "#3C3F58" }} />
              <AiOutlineInstagram size="2.5rem" />
              <AiOutlineTwitter size="2.5rem" />
            </div>
          </div>
          <div className="h-8vh" />
        </div>
      ) : (
        modalType === "report" && (
          <div className="p-lg-3 px-3 py-4">
            <div className="signInBorder">
              <span className="font-black black mx-3 reporttitle">{title}</span>
            </div>
            <div style={{marginTop: '10px'}}>
              <form className="mt-lg-3">
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-lg-nowrap">
                  <div className="signInInput greyLightBg br4 d-flex align-items-center my-lg-3 my-2 me-lg-5"
                    style={{ height: '48px' }}>
                    <input
                      name="fullName"
                      style={{ height: '48px' }}
                      type="text"
                      className="greyLightBg br4 flex-grow-1 px18"
                      autoComplete="off"
                      placeholder="Your name"
                      value={state.name}
                      onChange={onChange}
                    />
                    <div>
                      <FiUser size="1.5rem" className="darkGrey mx-3" />
                    </div>
                  </div>
                  {errors.required.name && (
                    <span className="error mx-2">
                      <FormattedMessage defaultMessage="Name is required!!" />
                    </span>
                  )}
                  <div className="signInInput greyLightBg br4 d-flex align-items-center my-lg-3 my-2"
                    style={{ height: '48px' }}>
                    <input
                      name="email"
                      style={{ height: '48px' }}
                      type="email"
                      className="greyLightBg br4 flex-grow-1 px18"
                      autoComplete="off"
                      placeholder="E-mail"
                      value={state.email}
                      onChange={onChange}
                    />
                    <div>
                      <OutLineMail size="1.5rem" className="darkGrey mx-3" />
                    </div>
                  </div>
                  {errors.required.email && (
                    <span className="error mx-2">
                      <FormattedMessage defaultMessage="Email is required!!" />
                    </span>
                  )}
                </div>

                <div className="d-flex flex-wrap flex-lg-nowrap">
                  <div className="signInInput greyLightBg br4 d-flex align-items-start my-lg-3 my-2">
                    <textarea
                      name="message"
                      style={{ height: '80px' }}
                      className="greyLightBg br4 px18"
                      placeholder="Leave your message"
                      rows="2"
                      cols="2"
                      value={state.message}
                      onChange={onChange}
                    />
                    <div>
                      <FiEdit size="1.5rem" className="darkGrey mx-3 my-3" />
                    </div>
                  </div>
                  {errors.required.message && (
                    <span className="error mx-2">
                      <FormattedMessage defaultMessage="Message is required!!" />
                    </span>
                  )}
                </div>
                <div className="d-lg-flex d-block align-items-center justify-content-end reportabusebtns">
                  <button
                    className="d-flex align-items-center justify-content-between font-bold px-3 py-2 br8 border2-primary
               button-primaryBg primary mx-lg-3 my-3 w-sm-100"
                    style={{ width: "251px", height: "58px" }}
                  >
                    <div
                      className="me-3"
                      onClick={close}
                      style={{ fontSize: "16px" }}
                    >
                      <FormattedMessage defaultMessage="Cancel" />
                    </div>
                    <ArrowRight size="1.5rem" />
                  </button>
                  <button
                    className="d-flex align-items-center justify-content-between font-bold px-3 py-2 br8 button-secondaryBorder
               white secondaryBg my-3 w-sm-100 reportabusebtn">
                    <div className="me-3" style={{ fontSize: "16px" }}>
                      <FormattedMessage defaultMessage="Report Abuse" />
                    </div>
                    <ArrowRight size="1.5rem" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        )
      )}
    </Modal>
  )
}

const mapStateToProps = ({
  shared: { loading },
  dialogReducer: { customOpen, modalType, title },
}) => {
  return { customOpen, loading, modalType, title }
}
export default connect(mapStateToProps, { toggleCustomModal })(CustomModal)
