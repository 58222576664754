import { navigate } from "gatsby"
import { USER_LOCAL_STORAGE_KEY } from "./instance"

export const onSuccessResponse = response => {
  return response.data
}

export const onFailedResponse = error => {
  if (
    error.response &&
    error.response.data &&
    error.response.data.code === 107
  ) {
    typeof window !== "undefined" &&
      localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
    navigate("/")
  }
  return error.response && Promise.reject(error.response.data)
}
