import React, { useLayoutEffect, useRef } from "react"
import { FormattedMessage, IntlProvider } from "react-intl"
import messages_en from "../i18n/compiled-locales/en.json"
import messages_ar from "../i18n/compiled-locales/ar.json"
import { connect } from "react-redux"
import instance from "../_api/instance"
import { authInstance } from "../_api/authApi"

export const GEN_ERR_MSG = (
  <FormattedMessage defaultMessage="Oops! Something went wrong" />
)

const LanguageProvider = ({ children, lang }) => {
  const contRef = useRef()
  const messages = {
    ar: messages_ar,
    en: messages_en,
  }
  let language = typeof lang === "string" && lang.split(/[-_]/)[0]

  if (!["ar", "en"].includes(language)) {
    language = "en"
  }

  useLayoutEffect(() => {
    if (language === "ar") {
      contRef.current.dir = "rtl"
    } else {
      contRef.current.dir = "ltr"
    }

    instance.defaults.headers.common["Accept-Language"] = language
    authInstance.defaults.headers.common["Accept-Language"] = language
  }, [language])

  return (
    <IntlProvider messages={messages[language]} locale={language}>
      <div className="body" ref={contRef}>
        {children}
      </div>
    </IntlProvider>
  )
}
const mapStateToProps = ({ settings: { lang } }) => ({ lang })

export default connect(mapStateToProps)(LanguageProvider)
