import qs from "query-string"
import { RequestQueryBuilder } from "@nestjsx/crud-request"

export const onSuccessRequest = config => {
  const qb = RequestQueryBuilder.create()

  const queryParams = []

  if (config && config.filters && config.filters.length > 0) {
    config.filters.forEach(filter => {
      if (filter.field === "rate") {
        queryParams.push(qs.stringify({ rate: filter.value }))
        return
      }
      qb.setFilter(filter)
    })
    queryParams.push(qb.resetCache().query())
  }
  if (config && config.sort && typeof config.sort === "string") {
    queryParams.push(qs.stringify({ sort: config.sort }))
  }
  if (config && config.page) {
    queryParams.push(qs.stringify({ page: config.page }))
  }

  let firstChar = ""
  if (config.url?.includes("?")) {
    if (!config.url?.endsWith("?")) {
      firstChar = "&"
    }
  } else {
    firstChar = "?"
  }

  if (queryParams.length > 0) {
    config.url = config.url?.concat?.(`${firstChar}${queryParams.join("&")}`)
  }
  return config
}

export const onFailedRequest = error => {
  return Promise.reject(error)
}
