import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import useForm from "../components/hooksComponents/useForm"
import background from "../assets/images/signInBackGround.png"
import { toggleModal } from "../actions/settingsActions"
import { RiLockPasswordLine } from "react-icons/ri"
import { FaFacebookF } from "react-icons/fa"
import { FcGoogle } from "react-icons/fc"
import { FiArrowRight, FiUser, FiHash } from "react-icons/fi"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import {
  login,
  signUp,
  forgetPassword,
  resetPassword,
  verifyCode,
} from "../actions/authActions"
import GoogleLogin from "react-google-login"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { HidePassword } from "../assets/images/hidePass"
import { ShowPassword } from "../assets/images/showPass"
import { FormattedMessage } from "react-intl"
import CloseIcon from "../assets/icons/closeIcon"
import OutLineMail from "../assets/icons/outLineMail"
import ArrowRight from "../assets/icons/arrowRight"

const SignInModal = props => {
  const { open, loading } = props

  const [modalState, setModalState] = useState("signIn")

  const {
    state,
    onChange,
    setErrors,
    errors,
    onChangeCheckBox,
    toggleValue,
    setState,
  } = useForm({})

  const closeModal = () => {
    props.toggleModal({ open: false })
    setModalState("signIn")
  }

  useEffect(() => {
    if (modalState !== "") {
      setState({})
    }
  }, [modalState])

  const TermsAndConditions = () => {
    const handleClick = () => {
      navigate("/terms")
      closeModal()
    }
    return (
      <p className="float-start px16 darkGrey py-4" style={{ width: "100%" }}>
        <FormattedMessage defaultMessage="By clicking 'Login' you confirm that you accept Simsar" />
        <button className="linkColor font-bold linkHover" onClick={handleClick}>
          <FormattedMessage defaultMessage="terms and conditions" />
        </button>
        <FormattedMessage defaultMessage="Please view our" />
        <button className="linkColor font-bold linkHover" onClick={handleClick}>
          <FormattedMessage defaultMessage="privacy policy" />
        </button>
        <FormattedMessage defaultMessage="to find out more about how we collect, use and keep your data safe." />
      </p>
    )
  }

  const responseGoogle = response => {
    // console.log({ response });
  }

  const responseFacebook = response => {
    // console.log(response);
  }

  const SocialRegisteringButtons = () => (
    <>
      <div className="z-5 br16 px-4 w-75 pt-5rem pb-0">
        <p className="primary font-bold px20">
          {modalState === "signIn"
            ? "Or Login via"
            : modalState === "signUp" && "Or Singup via"}
        </p>
      </div>
      <div className="w-xl-50 w-100 d-flex flex-column">
        <div
          className="d-flex align-items-start justify-content-start"
          style={{ width: "303px" }}
        >
          <GoogleLogin
            clientId="409993560081-ifropoonp103jrd2nd5ldtbgr8gvremf.apps.googleusercontent.com"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            render={renderProps => (
              <button
                type="button"
                className="z-1 whiteBg font-bold px20 primary br8 p-3 m-2 d-flex align-items-center flex-grow-1"
                onClick={renderProps.onClick}
                // disabled={renderProps.disabled}
              >
                <FcGoogle size="1.5rem" />
                <span className="mx-3">
                  <FormattedMessage defaultMessage="Google Account" />
                </span>
              </button>
            )}
            cookiePolicy={"single_host_origin"}
          />
        </div>
        <div
          className="d-flex align-items-start justify-content-start"
          style={{ width: "303px" }}
        >
          <FacebookLogin
            appId="584817626000113"
            fields="name,email,picture"
            callback={responseFacebook}
            render={renderProps => (
              <button
                disabled={renderProps.isDisabled}
                onClick={renderProps.onClick}
                type="button"
                className="z-1 whiteBg font-bold px20 primary br8 p-3 m-2 d-flex align-items-center flex-grow-1"
              >
                <FaFacebookF className="facebook" size="1.5rem" />
                <span className="mx-3">
                  <FormattedMessage defaultMessage="Facebook Account" />
                </span>
              </button>
            )}
          />
        </div>
      </div>
    </>
  )

  const onSignUp = e => {
    e.preventDefault()
    if (!state.name) {
      setErrors({
        ...errors,
        required: { name: true },
      })
      return
    }
    if (!state.email) {
      setErrors({
        ...errors,
        required: { email: true },
      })
      return
    }
    if (!state.password) {
      setErrors({
        ...errors,
        required: { password: true },
      })
      return
    }
    if (!state.isCheckedTerms) {
      setErrors({
        ...errors,
        required: { isCheckedTerms: true },
      })
      return
    }
    props
      .signUp(state)
      .then(() => {
        setModalState("verifyCode")
      })
      .catch(() => {})
  }

  const onSignIn = e => {
    e.preventDefault()
    if (!state.email) {
      setErrors({
        ...errors,
        required: { email: true },
      })
      return
    }
    if (!state.password) {
      setErrors({
        ...errors,
        required: { password: true },
      })
      return
    }
    props
      .login(state)
      .then(() => {
        closeModal()
      })
      .catch((err) => {
        console.log('this is error', err);
      })
  }

  const onForgetPassword = e => {
    e.preventDefault()
    if (!state.email) {
      setErrors({
        ...errors,
        required: { email: true },
      })
      return
    }
    props.forgetPassword(state)
  }

  const onResetPassword = e => {
    e.preventDefault()
    if (!state.newPassword) {
      setErrors({
        ...errors,
        required: { newPassword: true },
      })
      return
    }
    props.resetPassword(state)
  }

  const onVerifyCode = e => {
    e.preventDefault()
    if (!state.verificationCode) {
      setErrors({
        ...errors,
        required: { verificationCode: true },
      })
      return
    }
    props
      .verifyCode(state)
      .then(() => props.closeModal())
      .catch(() => {})
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      overlayClassName="modalOverlay"
      className="signInModal"
      ariaHideApp={false}
    >
      <div className="signInBorder d-flex align-items-center justify-content-between m-4">
        <span
          className="font-black mx-3 myfavtitle"
        >
          {modalState === "signIn"
            ? "Login"
            : modalState === "forgetPassword" || modalState === "resetPassword"
            ? "Forget Password"
            : (modalState === "signUp" ||
                modalState === "verifyCode" ||
                modalState === "createPassword") &&
              "Sign up"}
        </span>
        <button className="primary" onClick={closeModal}>
          <CloseIcon size="1.5rem" />
        </button>
      </div>

      <div className="d-flex flex-wrap flex-lg-nowrap br32 h-70vh gradientBg overflow-scroll hideScrollBar">
        <div className="overflow-auto hideScrollBar w-xl-75 w-100">
          {modalState === "signIn" && (
            <form className="px-1 px-lg-5 pb-5 mx-3" onSubmit={onSignIn}>
              <div className="greyLight32 px16 mx-auto">
                <FormattedMessage defaultMessage="Welcome to Simsar" />
              </div>
              <div
                className="primary font-bold px32 mx-auto"
                style={{ letterSpacing: "-0.5", width: "100%" }}
              >
                <FormattedMessage defaultMessage="Enter account information" />
              </div>
              <div className="my-4" style={{ width: "80.5%" }}>
                <div className="signInInput greyLightBg br4 d-flex align-items-center">
                  <input
                    name="email"
                    type="email"
                    id="inputID"
                    className="greyLightBg br4 flex-grow-1 px18"
                    autoComplete="off"
                    placeholder="Email"
                    value={state.email}
                    onChange={onChange}
                  />
                  <div>
                    <OutLineMail size="1.5rem" className="darkGrey mx-3" />
                  </div>
                </div>
                {errors.required.email && (
                  <span className="error mx-2">
                    <FormattedMessage defaultMessage="Email is required!!" />
                  </span>
                )}
              </div>
              <div className="my-3" style={{ width: "80.5%" }}>
                <div className="signInInput greyLightBg br4 d-flex align-items-center">
                  <input
                    name="password"
                    type="password"
                    className="greyLightBg br4 flex-grow-1 px18"
                    placeholder="Password"
                    value={state.password}
                    onChange={onChange}
                  />
                  <div>
                    <RiLockPasswordLine
                      size="1.5rem"
                      className="darkGrey mx-3"
                    />
                  </div>
                </div>
                {errors.required.password && (
                  <span className="error mx-2">
                    <FormattedMessage defaultMessage="Password is required!!" />
                  </span>
                )}
                <button
                  type="button"
                  onClick={() => setModalState("forgetPassword")}
                  className="linkColor linkHover float-end px16 font-bold mb-4"
                >
                  <FormattedMessage defaultMessage="Forget my password!" />
                </button>
              </div>

              <button className="primaryBg px20 white br8 signInButton mt-5rem mb-3 btnShadow d-flex align-items-center justify-content-center button-primaryBorder">
                {!!loading && (
                  <span
                    className="spinner-border spinner-border-sm me-3"
                    role="status"
                    aria-hidden="true"
                  >
                    {" "}
                  </span>
                )}
                <span className="font-bold">
                  <FormattedMessage defaultMessage="Login" />
                </span>
              </button>
              <TermsAndConditions />
              <div className="d-lg-none py-5">
                {(modalState === "signIn" || modalState === "signUp") && (
                  <SocialRegisteringButtons />
                )}
                <button
                  className="primary font-bold py-3 d-lg-none"
                  onClick={() => setModalState("signUp")}
                >
                  <span className="mx-3 linkHover">
                    <FormattedMessage defaultMessage="Create New Account" />
                  </span>
                  <ArrowRight size="1.5rem" />
                </button>
              </div>
            </form>
          )}
          {modalState === "forgetPassword" && (
            <form
              className="px-1 px-lg-5 pb-5 mx-3"
              onSubmit={onForgetPassword}
            >
              <div className="greyLight32 px16 mx-auto">
                <FormattedMessage defaultMessage="Welcome to Simsar" />
              </div>
              <div className="primary font-bold px32 mx-auto">
                <FormattedMessage defaultMessage="Please enter your email" />
              </div>
              <div className="signInInput greyLightBg br4 d-flex align-items-center mt-4">
                <input
                  name="email"
                  type="email"
                  className="greyLightBg br4 flex-grow-1 px18"
                  autoComplete="off"
                  placeholder="Email"
                  value={state.email}
                  onChange={onChange}
                />
                <div>
                  <OutLineMail size="1.5rem" className="darkGrey mx-3" />
                </div>
              </div>
              {errors.required.email && (
                <span className="error mx-2">
                  <FormattedMessage defaultMessage="Email is required!!" />
                </span>
              )}
              <button className="primaryBg px20 white br8 signInButton mt-5 mb-4 btnShadow d-flex align-items-center justify-content-center button-primaryBorder">
                {!!loading && (
                  <span
                    className="spinner-border spinner-border-sm me-3"
                    role="status"
                    aria-hidden="true"
                  >
                    {" "}
                  </span>
                )}
                <span
                  className="font-bold"
                  onClick={() => setModalState("resetPassword")}
                >
                  <FormattedMessage defaultMessage="Reset Password" />
                </span>
              </button>
              <div className="darkGrey px16 mx-auto">
                <FormattedMessage defaultMessage="We will send an email with a rest password link" />
              </div>
              <button
                className="primary font-bold py-3 d-lg-none"
                onClick={() => setModalState("signIn")}
              >
                <span className="mx-3 linkHover">
                  <FormattedMessage defaultMessage="Back to login" />
                </span>
                <ArrowRight size="1.5rem" />
              </button>
            </form>
          )}
          {modalState === "resetPassword" && (
            <form className="px-1 px-lg-5 pb-5 mx-3" onSubmit={onResetPassword}>
              <div className="greyLight32 px16 mx-auto">
                <FormattedMessage defaultMessage="Welcome to Simsar" />
              </div>
              <div
                className="primary font-bold px32 mx-auto"
                style={{ width: "510px" }}
              >
                <FormattedMessage defaultMessage="Please create a new password" />
              </div>
              <div
                className="signInInput greyLightBg br4 d-flex align-items-center mt-4"
                style={{ width: "81%" }}
              >
                <input
                  name="newPassword"
                  type="password"
                  id="inputID"
                  className="greyLightBg br4 flex-grow-1 px18"
                  autoComplete="off"
                  placeholder="New Password"
                  value={state.newPassword}
                  onChange={onChange}
                />
                <div>
                  <FiHash size="1.5rem" className="darkGrey mx-3" />
                </div>
              </div>
              {errors.required.newPassword && (
                <span className="error mx-2">
                  <FormattedMessage defaultMessage="Password is required!!" />
                </span>
              )}
              <button className="primaryBg px20 white br8 signInButton mt-5 mb-4 btnShadow d-flex align-items-center justify-content-center">
                {!!loading && (
                  <span
                    className="spinner-border spinner-border-sm me-3"
                    role="status"
                    aria-hidden="true"
                  >
                    {" "}
                  </span>
                )}
                <span className="font-bold" style={{ width: "65%" }}>
                  <FormattedMessage defaultMessage="Create New Password" />
                </span>
              </button>
              <button
                className="primary font-bold py-3 d-lg-none"
                onClick={() => setModalState("signIn")}
              >
                <span className="mx-3 linkHover">
                  <FormattedMessage defaultMessage="Back to login" />
                </span>
                <ArrowRight size="1.5rem" />
              </button>
            </form>
          )}
          {modalState === "signUp" && (
            <form className="px-1 px-lg-5 pb-5 mx-3" onSubmit={onSignUp}>
              <div className="greyLight32 px16 mx-auto">
                <FormattedMessage defaultMessage="Welcome to login" />
              </div>
              <div className="primary font-bold px32 mx-auto">
                <FormattedMessage defaultMessage="Create a new account" />
              </div>
              <div className="my-3" style={{ width: "83%" }}>
                <div className="signInInput greyLightBg br4 d-flex align-items-center mt-4">
                  <input
                    name="name"
                    type="text"
                    className="greyLightBg br4 flex-grow-1 px18"
                    autoComplete="off"
                    placeholder="Full Name"
                    value={state.name}
                    onChange={onChange}
                  />
                  <div>
                    <FiUser size="1.5rem" className="darkGrey mx-3" />
                  </div>
                </div>
                {errors.required.name && (
                  <span className="error mx-2">
                    <FormattedMessage defaultMessage="Name is required!!" />
                  </span>
                )}
              </div>
              <div className="my-3" style={{ width: "83%" }}>
                <div className="signInInput greyLightBg br4 d-flex align-items-center mt-4">
                  <input
                    name="email"
                    type="email"
                    className="greyLightBg br4 flex-grow-1 px18"
                    autoComplete="off"
                    placeholder="Your Email"
                    value={state.email}
                    onChange={onChange}
                  />
                  <div>
                    <OutLineMail size="1.5rem" className="darkGrey mx-3" />
                  </div>
                </div>
                {errors.required.email && (
                  <div className="error mx-2 mb-2">
                    <FormattedMessage defaultMessage="Email is required!!" />
                  </div>
                )}
              </div>
              <div className="my-3" style={{ width: "83%" }}>
                <div className="signInInput greyLightBg br4 d-flex align-items-center mt-4">
                  <input
                    name="password"
                    type={!!state.showPassword ? "text" : "password"}
                    className="greyLightBg br4 flex-grow-1 px18"
                    autoComplete="off"
                    placeholder="Password"
                    value={state.password}
                    onChange={onChange}
                  />
                  <div
                    className="pointer"
                    onClick={() => toggleValue("showPassword")}
                  >
                    <span className="mx-3">
                      {!state.showPassword ? (
                        <HidePassword />
                      ) : (
                        <ShowPassword />
                      )}
                    </span>
                  </div>
                </div>
                {errors.required.password && (
                  <div className="error mx-2 mb-2">
                    <FormattedMessage defaultMessage="Password is required!!" />
                  </div>
                )}
              </div>
              <div className="darkGrey px16 input-checkbox">
                <input
                  type="checkbox"
                  className="mx-2"
                  name="isCheckedTerms"
                  checked={state.isCheckedTerms || false}
                  onChange={onChangeCheckBox}
                />
                <label>
                  <FormattedMessage defaultMessage="I agree to Simsar terms & conditions" />
                </label>
              </div>
              {errors.required.isCheckedTerms && (
                <div className="error mx-2 mb-2">
                  <FormattedMessage defaultMessage="Please check terms & conditions to continue!" />
                </div>
              )}
              <button
                className="primaryBg px20 white br8 signInButton mt-5 mb-4 btnShadow d-flex align-items-center justify-content-center button-primaryBorder"
                style={{ width: "52%" }}
              >
                {!!loading && (
                  <span
                    className="spinner-border spinner-border-sm me-3"
                    role="status"
                    aria-hidden="true"
                  >
                    {" "}
                  </span>
                )}
                <span className="font-bold">
                  <FormattedMessage defaultMessage="Sign up" />
                </span>
              </button>
              <TermsAndConditions />
              <div className="d-lg-none py-5">
                {(modalState === "signIn" || modalState === "signUp") && (
                  <SocialRegisteringButtons />
                )}
                <button
                  className="primary font-bold py-3 d-lg-none"
                  onClick={() => setModalState("signIn")}
                >
                  <span className="mx-3 linkHover">
                    <FormattedMessage defaultMessage="I already have an account" />
                  </span>
                  <ArrowRight size="1.5rem" />
                </button>
              </div>
            </form>
          )}
          {modalState === "verifyCode" && (
            <form className="px-1 px-lg-5 pb-5 mx-3" onSubmit={onVerifyCode}>
              <div className="greyLight32 px16 mx-auto">
                <FormattedMessage defaultMessage="Enter verification code" />
              </div>
              <div className="primary font-bold px32 mx-auto">
                <FormattedMessage defaultMessage="Verification code is sent to your email, please verify your account" />
              </div>
              <div className="signInInput greyLightBg br4 d-flex align-items-center mt-4">
                <input
                  name="verificationCode"
                  type="text"
                  className="greyLightBg br4 flex-grow-1 px18"
                  autoComplete="off"
                  placeholder="Verification Code"
                  value={state.verificationCode}
                  onChange={onChange}
                />
                <div>
                  <FiHash size="1.5rem" className="darkGrey mx-3" />
                </div>
              </div>
              {errors.required.verificationCode && (
                <div className="error mx-2 mb-2">
                  <FormattedMessage defaultMessage="Verification code is required!!" />
                </div>
              )}
              <button
                type="submit"
                className="primaryBg px20 white br8 signInButton mt-5 mb-4 btnShadow d-flex align-items-center justify-content-center button-primaryBorder"
              >
                {!!loading && (
                  <span
                    className="spinner-border spinner-border-sm me-3"
                    role="status"
                    aria-hidden="true"
                  >
                    {" "}
                  </span>
                )}
                <span
                  className="font-bold"
                  // onClick={() => setModalState("createPassword")}
                >
                  <FormattedMessage defaultMessage="Verify Number" />
                </span>
              </button>
              <button
                className="primary font-bold py-3 d-lg-none"
                onClick={() => setModalState("signIn")}
              >
                <span className="mx-3 linkHover">
                  <FormattedMessage defaultMessage="Back to login" />
                </span>
                <ArrowRight size="1.5rem" />
              </button>
            </form>
          )}
          {/*          {modalState === "createPassword" && (
            <form className="px-1 px-lg-5 pt-5 pb-5 mx-3">
              <div className="greyLight32 px16 mx-auto">Account Password</div>
              <div className="primary font-bold px32 mx-auto">
                create a password
              </div>
              <div className="signInInput greyLightBg br4 d-flex align-items-center mt-4">
                <input
                  name="password"
                  type="password"
                  className="greyLightBg br4 flex-grow-1 px18"
                  autoComplete="off"
                  placeholder="Type password"
                  value={state.password}
                  onChange={onChange}
                />
                <div>
                  <FiHash size="1.5rem" className="darkGrey mx-3" />
                </div>
              </div>
              <button className="primaryBg px20 white br8 signInButton mt-5 mb-4 btnShadow d-flex align-items-center justify-content-center">
                { <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"> </span> }
                <span className="font-bold">
                <FormattedMessage defaultMessage="Create Account" /></span>
              </button>
              <button
                className="primary font-bold py-3 d-lg-none"
                onClick={() => setModalState("signIn")}
              >
                <span className="mx-3 linkHover">
                <FormattedMessage defaultMessage="Back to login" />
                </span>
                <ArrowRight size="1.5rem" />
              </button>
            </form>
          )}*/}
        </div>

        <div className="relative w-100">
          <div className="d-lg-block d-none" style={{ marginTop: "-40px" }}>
            {(modalState === "signIn" || modalState === "signUp") && (
              <SocialRegisteringButtons />
            )}
          </div>
          <img
            src={background}
            alt="background"
            className="d-lg-flex d-none h-100 w-100 absolute right-0 bottom-0 pe-4 pt-6rem"
          />
          <div className="d-lg-flex d-none absolute right-0 bottom-0 z-1">
            {modalState === "signIn" ? (
              <button
                className="primary font-bold p-5"
                onClick={() => setModalState("signUp")}
              >
                <span className="mx-3 linkHover">
                  <FormattedMessage defaultMessage="Create New Account" />
                </span>
                <ArrowRight size="1.5rem" />
              </button>
            ) : modalState === "signUp" ? (
              <button
                className="primary font-bold p-5"
                onClick={() => setModalState("signIn")}
              >
                <span className="mx-3 linkHover">
                  <FormattedMessage defaultMessage="I already have an account" />
                </span>
                <ArrowRight size="1.5rem" />
              </button>
            ) : (
              modalState !== "signIn" &&
              modalState !== "signUp" && (
                <button
                  className="primary font-bold p-5"
                  onClick={() => setModalState("signIn")}
                >
                  <span className="mx-3 linkHover">
                    <FormattedMessage defaultMessage="Back to login" />
                  </span>
                  <ArrowRight size="1.5rem" />
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
const mapStateToProps = ({ shared: { loading }, dialogReducer: { open } }) => {
  return { open, loading }
}
export default connect(mapStateToProps, {
  toggleModal,
  login,
  signUp,
  forgetPassword,
  resetPassword,
  verifyCode,
})(SignInModal)
