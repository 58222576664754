import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Range, getTrackBackground } from "react-range"
import { connect, useSelector } from "react-redux"
import { setPropertyFiltersField } from "../../actions/settingsActions"
import { numberWithCommas } from "../../utils"
import Button from "../button"

const STEP = 1000000

export const MIN = 0
export const MAX = 1000000000

const PriceRange = ({
  price = [],
  setPropertyFiltersField,
  min = MIN,
  max = MAX,
  hasBackground = true,
  largerNums = true,
  customSizeClass = "",
}) => {
  const lang = useSelector(state => state?.settings?.lang)

  const [values, setValues] = useState(price)

  const setPriceRange = () => setPropertyFiltersField("price", values)

  useEffect(() => {
    price && setValues(price)
  }, [price])
  useEffect(() => {
    if (!hasBackground) {
      setPriceRange()
    }
  }, [values])

  return (
    <div className="w-100 mb-3">
      <div
        className="px12 letter-wide text-uppercase font-800"
        style={{ color: "#ADADAD" }}
      >
        <FormattedMessage defaultMessage="Price Range" />
      </div>
      <div className="w-100 d-flex justify-content-between mb-4">
        <span
          className={`primary px15 font-800 text-uppercase letter-wide px-1 ${customSizeClass}`}
          style={{
            fontSize: largerNums ? "15px" : "16px",
            backgroundColor: hasBackground ? "#F2F2F2" : "transparent",
            borderRadius: "8px",
            width: "auto",
            height: "46px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormattedMessage
            defaultMessage="SP {value}"
            values={{ value: numberWithCommas(values?.[0]) }}
          />
        </span>

        <span
          className={`primary px15 font-800 text-uppercase letter-wide px-1 ${customSizeClass}`}
          style={{
            fontSize: largerNums ? "15px" : "16px",
            backgroundColor: hasBackground ? "#F2F2F2" : "transparent",
            borderRadius: "8px",
            width: "auto",
            height: "46px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormattedMessage
            defaultMessage="SP {value}"
            values={{ value: numberWithCommas(values?.[1]) }}
          />
        </span>
      </div>

      <div className="d-flex align-items-center space-x-8">
        <div className="flex-1">
          <div className="mx-auto" style={{ width: "90%" }}>
            <Range
              step={STEP}
              min={min}
              max={max}
              values={values}
              onChange={setValues}
              rtl={lang === "ar"}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "8px",
                    width: "100%",
                    background: getTrackBackground({
                      values: values,
                      colors: ["#F6F7FB", "#222222", "#F6F7FB"],
                      min: min,
                      max: max,
                      rtl: lang === "ar",
                    }),
                    borderRadius: "4px",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props, index }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "24px",
                    width: "14px",
                    backgroundColor: "#222222",
                    borderRadius: "4px",
                    outline: "none",
                  }}
                />
              )}
            />
          </div>
        </div>

        {hasBackground ? (
          <Button
            style={{ width: "42px", height: "42px" }}
            disabled={values?.join?.(",") === price?.join?.(",")}
            color="secondary"
            onClick={setPriceRange}
          >
            <FormattedMessage defaultMessage="Go" />
          </Button>
        ) : null}
      </div>
    </div>
  )
}

const mapStateToProps = ({ settings: { propertyFilters } }) => ({
  price: propertyFilters?.price,
})
export default connect(mapStateToProps, { setPropertyFiltersField })(PriceRange)
