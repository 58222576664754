import {
  SET_HOME_SLIDER,
  SET_PROPERTY_TYPES,
  SET_AMENITIES,
  SET_PROPERTIES,
  SET_SIZE_UNITS,
} from "../actions/types"

const INIT_STATE = {
  homeSlider: [],
  propertyTypes: {},
  amenities: {},
  sizeUnits: {},
  properties: {
    data: [],
  },
}

export default function dataReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case SET_HOME_SLIDER:
      return { ...state, homeSlider: action.payload || [] }
    case SET_PROPERTY_TYPES:
      return { ...state, propertyTypes: action.payload || {} }
    case SET_AMENITIES:
      return { ...state, amenities: action.payload || {} }
    case SET_SIZE_UNITS:
      return { ...state, sizeUnits: action.payload || {} }
    case SET_PROPERTIES:
      return { ...state, properties: action.payload || {} }
    default:
      return { ...state }
  }
}
