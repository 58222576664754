import React from "react"
import Modal from "react-modal"
import { FormattedMessage } from "react-intl"
import { connect, useDispatch } from "react-redux"
import { closeConfirmModal } from "../../actions/settingsActions"
import Button from "../button"
import ArrowRight from "../../assets/icons/arrowRight"

const ConfirmModal = ({ open, title, msg, onConfirm, ...props }) => {
  const closeModal = () => {
    props.closeConfirmModal()
  }
  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      overlayClassName="modalOverlay"
      className="confirm-modal"
      ariaHideApp={false}
    >
      <div className="p-4 whiteBg br8 quitadmodal">
        <h1
          className="px56 text-decorated font-bold black mb-3"
          style={{ fontWeight: "900" }}
        >
          {title ? title : <FormattedMessage defaultMessage="Confirm" />}
        </h1>
        <p className="px20 darkGrey youwillose">
          {msg ? msg : <FormattedMessage defaultMessage="Are You Sure?" />}
        </p>
        <div
          className="justify-content-lg-end mt-4 quitcreatingadbtns"
          style={{ gap: "32px", paddingTop: "160px", display: "flex" }}
        >
          <Button
            onClick={closeModal}
            color="white"
            className="primary font-bold px16 br8 custom-border-primary pointer continueadbtn"
          >
            <FormattedMessage defaultMessage="Continue" />
            <ArrowRight size="1.5rem" />
          </Button>
          <Button
            className="canceladbtn"
            onClick={() => {
              onConfirm()
              closeModal()
            }}
            color="secondary"
          >
            {title ? title : <FormattedMessage defaultMessage="Cancel Ad" />}
            <ArrowRight size="1.5rem" />
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ settings: { confirmModal } }) => ({
  open: confirmModal?.open,
  ...confirmModal?.props,
})
export default connect(mapStateToProps, { closeConfirmModal })(ConfirmModal)
