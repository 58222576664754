import {
  SET_LANG,
  SET_GEN_MODAL,
  SET_CONFIRM_MODAL,
  SET_PROPERTY_FILTERS,
  SET_PROPERTY_FILTERS_FIELD,
  SET_SORT,
  SET_VIEW_MODE,
} from "../actions/types"
import { MAX, MIN } from "../components/filter/priceRange"

const propFilterInitialState = {
  offerType: null,
  isWithFurniture: null,
  isConstructionCompleted: null,
  bedRoomsCount: null,
  bathsCount: null,
  relationship: null,
  type: [],
  city: null,
  amenities: [],
  rentCheck: [],
  price: [MIN, MAX],
  size: {
    min: "",
    max: "",
  },
}

const INIT_STATE = {
  lang:
    (typeof window !== "undefined" && localStorage.getItem("lang")) ||
    (typeof window !== "undefined" && navigator.language.split(/[-_]/)[0]) ||
    "en",
  generalModal: {
    open: false,
    Component: () => null,
    componentProps: {},
    modalClassName: "",
  },
  confirmModal: {
    open: false,
    props: {
      title: "",
      msg: "",
      onConfirm: () => { },
    },
  },
  propertyFilters: propFilterInitialState,
  sort: "",
  viewMode: "horizontal",
}

const settingsReducer = (state = INIT_STATE, action) => {
  const { payload } = action

  switch (action.type) {
    case SET_LANG:
      const lang = ["ar", "en"].includes(action.payload) ? action.payload : "en"
      localStorage.setItem("lang", lang)

      return { ...state, lang: lang }
    case SET_GEN_MODAL:
      return {
        ...state,
        generalModal: {
          open: payload?.open,
          Component: payload?.Component
            ? payload.Component
            : state.generalModal.Component,
          componentProps:
            typeof payload?.componentProps === "object"
              ? payload.componentProps
              : state.generalModal.componentProps,
          modalClassName:
            payload?.modalClassName || state.generalModal.modalClassName,
        },
      }

    case SET_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          open: payload?.open,
          props:
            typeof payload.props === "object"
              ? payload?.props
              : state.confirmModal?.props,
        },
      }

    case SET_PROPERTY_FILTERS:
      if (!payload) {
        return {
          ...state,
          propertyFilters: propFilterInitialState,
        }
      }
      return {
        ...state,
        propertyFilters: payload,
      }

    case SET_PROPERTY_FILTERS_FIELD:
      const { field } = action
      if (field && state?.propertyFilters?.hasOwnProperty(field)) {
        return {
          ...state,
          propertyFilters: {
            ...state.propertyFilters,
            [field]: payload,
          },
        }
      }
      return {
        ...state,
      }
    case SET_SORT:
      return { ...state, sort: payload }

    case SET_VIEW_MODE:
      return { ...state, viewMode: payload || "horizontal" }

    default:
      return { ...state }
  }
}
export default settingsReducer
