import { capitalize } from "@material-ui/core"
import { NotificationManager } from "react-notifications"
import { GEN_ERR_MSG } from "../i18n"

export const notificationTime = 4000

export function error(msg = GEN_ERR_MSG) {
  msg && NotificationManager.error(msg, "", notificationTime)
}

export function success(msg) {
  msg && NotificationManager.success(msg, "", notificationTime)
}

export function caseInsensitiveCompare(a, b) {
  if (!(typeof a === "string" && typeof b === "string")) return false
  return !Boolean(a.localeCompare(b, undefined, { sensitivity: "accent" }))
}

export function camelCaseToLabel(s) {
  if (typeof s !== "string") return null
  return s.replace(/([a-z0-9])([A-Z])/g, "$1 $2")
}

export function getLabel(s) {
  if (typeof s !== "string") return null
  return capitalize(camelCaseToLabel(s))
}

export function numberWithCommas(x) {
  if (!x || typeof x !== "number") return "0"
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
export const isValidEmail = email => {
  const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regEx.test(email);
}

export const isValidPassword =  password => {
  if (password === '') return false;
  const passwordPattern = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z])(?=.{8,}).*$/;
  return password.match(passwordPattern);
}

export const isValidSyrianPhoneNumber = phoneNum => {
  if(typeof phoneNum !== 'string') return false;

  const num = phoneNum.slice(1)

  return (
    (num.startsWith("9639") || num.startsWith("96309")) &&
    num.length <= 13 &&
    typeof Number(num) === "number"
  )
}

export const scrollTop = () => {
  typeof window !== "undefined" &&
    window.scrollTo({ top: 0, behavior: "smooth" })
}

export const truncate = (str, length) => {
  if(!str) return ''
  return str?.length < length ? str : str?.slice(0, length) + "..."
}
