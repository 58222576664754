import React from "react"

const Spinner = () => {
  return (
    <span
      className="spinner-border spinner-border-sm mx-2"
      role="status"
      aria-hidden="true"
    />
  )
}

export default Spinner
