import { combineReducers } from 'redux';
import dialogReducer from './dialogReducer';
import userReducer from "./userReducer"
import settingsReducer from "./settingsReducer"
import dataReducer from "./dataReducer"
import sharedReducer from "./sharedReducer"

const reducers = combineReducers({
  dialogReducer,
  user: userReducer,
  settings: settingsReducer,
  data: dataReducer,
  shared: sharedReducer,
})

export default reducers;
