import React from "react";
import { Provider } from "react-redux";
import { NotificationContainer } from 'react-notifications';
import createStore from "./index";
import SignInModal from "../components/signInModal";
import CustomModal from "../components/customModal";
import LanguageProvider from "../i18n"
import { ThemeProvider, createTheme } from "@material-ui/core/styles"
import GeneralPurposeModal from "../components/modals/generalPurposeModal"
import MainApp from "./mainApp"
import ConfirmModal from "../components/modals/confirmModal"

const Wrapper = ({ element }) => {
  const store = createStore()

  const theme = createTheme({
    palette: {
      primary: {
        main: "#3C3F58",
      },
      secondary: {
        main: "#3BBA9C",
      },
    },
  })

  return (
    <Provider store={store}>
      <LanguageProvider>
        <ThemeProvider theme={theme}>
          <NotificationContainer />
          <MainApp>{element}</MainApp>
          <SignInModal />
          <GeneralPurposeModal />
          <ConfirmModal />
          <CustomModal />
        </ThemeProvider>
      </LanguageProvider>
    </Provider>
  )
}
export default Wrapper;