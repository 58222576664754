import React from 'react';

export const HidePassword = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.12 22.12C21.8454 22.4148 21.5141 22.6512 21.1462 22.8151C20.7782 22.9791 20.3809 23.0673 19.9781 23.0744C19.5753 23.0815 19.1752 23.0074 18.8016 22.8565C18.4281 22.7056 18.0887 22.4811 17.8038 22.1962C17.519 21.9113 17.2944 21.572 17.1435 21.1984C16.9926 20.8249 16.9185 20.4247 16.9256 20.0219C16.9327 19.6191 17.0209 19.2219 17.1849 18.8539C17.3488 18.4859 17.5853 18.1547 17.88 17.88M25.94 25.94C24.2306 27.243 22.1491 27.9649 20 28C13 28 9 20 9 20C10.2439 17.6819 11.9691 15.6566 14.06 14.06L25.94 25.94ZM17.9 12.24C18.5883 12.0789 19.2931 11.9984 20 12C27 12 31 20 31 20C30.393 21.1356 29.6691 22.2048 28.84 23.19L17.9 12.24Z"
        stroke="#616E86"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9L31 31"
        stroke="#616E86"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}