import {
  TOGGLE_MODAL,
  SET_LANG,
  SET_GEN_MODAL,
  SET_CONFIRM_MODAL,
  TOGGLE_CUSTOM_MODAL,
  SET_PROPERTY_FILTERS,
  SET_PROPERTY_FILTERS_FIELD,
  SET_LOADING,
  SET_SORT,
  SET_VIEW_MODE,
} from "./types"

export const toggleModal = value => dispatch => {
  dispatch({ type: TOGGLE_MODAL, payload: value })
}

export const toggleCustomModal = value => dispatch => {
  dispatch({ type: TOGGLE_CUSTOM_MODAL, payload: value })
}

export const toggleLanguage = lang => dispatch => {
  dispatch({ type: SET_LANG, payload: lang })
}

export const openGeneralModal = () => dispatch => {
  dispatch({
    type: SET_GEN_MODAL,
    payload: {
      open: true,
    },
  })
}

export const closeGeneralModal = () => dispatch => {
  dispatch({
    type: SET_GEN_MODAL,
    payload: {
      open: false,
    },
  })
}

export const setGeneralModal =
  (modalInfo = {}) =>
  dispatch => {
    dispatch({
      type: SET_GEN_MODAL,
      payload: modalInfo,
    })
  }

export const setConfirmModal =
  (modalInfo = {}) =>
  dispatch => {
    dispatch({
      type: SET_CONFIRM_MODAL,
      payload: modalInfo,
    })
  }

export const closeConfirmModal = () => dispatch => {
  dispatch(
    setConfirmModal({
      open: false,
    })
  )
}

export const openConfirmModal =
  ({ title, msg, onConfirm }) =>
  dispatch => {
    dispatch(
      setConfirmModal({
        open: true,
        props: {
          title,
          msg,
          onConfirm,
        },
      })
    )
  }

export const setPropertyFilters =
  (filters = {}) =>
  dispatch => {
    dispatch({
      type: SET_PROPERTY_FILTERS,
      payload: filters,
    })
  }

export const clearPropertyFilters = () => dispatch => {
  dispatch({
    type: SET_PROPERTY_FILTERS,
    payload: null,
  })
}

export const setPropertyFiltersField = (field, payload) => dispatch => {
  dispatch({
    type: SET_PROPERTY_FILTERS_FIELD,
    payload,
    field,
  })
}

export const setLoading = payload => dispatch => {
  dispatch({
    type: SET_LOADING,
    payload,
  })
}

export const setSort = sort => dispatch => {
  dispatch({ type: SET_SORT, payload: sort })
}

export const setViewMode = mode => dispatch => {
  dispatch({ type: SET_VIEW_MODE, payload: mode })
}
