// Auth
export const LOGIN_USER = "LOGIN_USER"
export const LOGOUT_USER = "LOGOUT_USER"
export const SET_LOADING = "SET_LOADING"
export const TOGGLE_MODAL = "TOGGLE_MODAL"
export const GET_MY_PROPERTIES = "GET_MY_PROPERTIES"
export const GET_MY_FAVORITES = "GET_MY_FAVORITES"
export const GET_MY_FOLLOWERS = "GET_MY_FOLLOWERS"
export const GET_MY_NOTIFICATIONS = "GET_MY_NOTIFICATIONS"

// settings
export const SET_LANG = "SET_LANG"
export const SET_GEN_MODAL = "SET_GEN_MODAL"
export const SET_CONFIRM_MODAL = "SET_CONFIRM_MODAL"
export const TOGGLE_CUSTOM_MODAL = "TOGGLE_CUSTOM_MODAL"
export const SET_PROPERTY_FILTERS = "SET_PROPERTY_FILTERS"
export const SET_PROPERTY_FILTERS_FIELD = "SET_PROPERTY_FILTERS_FIELD"
export const SET_SORT = "SET_SORT"
export const SET_VIEW_MODE = "SET_VIEW_MODE"

// data
export const SET_HOME_SLIDER = "SET_HOME_SLIDER"
export const SET_PROPERTY_TYPES = "SET_PROPERTY_TYPES"
export const SET_AMENITIES = "SET_AMENITIES"
export const SET_PROPERTIES = "SET_PROPERTIES"
export const SET_SIZE_UNITS = "SET_SIZE_UNITS"
