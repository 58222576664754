import React from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import { closeGeneralModal } from "../../actions/settingsActions"

const GeneralPurposeModal = ({
  open,
  Component = () => null,
  componentProps = {},
  modalClassName,
  ...props
}) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => props.closeGeneralModal()}
      overlayClassName="modalOverlay"
      className={modalClassName}
      ariaHideApp={false}
      preventScroll={true}
      closeTimeoutMS={400}
    >
      <Component {...componentProps} />
    </Modal>
  )
}

const mapStateToProps = ({ settings: { generalModal } }) => ({
  ...generalModal,
})
export default connect(mapStateToProps, { closeGeneralModal })(
  GeneralPurposeModal
)
