import { useState, useEffect } from "react"

const useForm = formState => {
  const [state, setState] = useState(formState)
  const [errors, setErrors] = useState({
    required: {},
    invalid: {},
  })

  const onChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const onChangeCheckBox = e => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    })
  }

  const toggleValue = item => {
    setState({
      ...state,
      [item]: !state[item],
    })
  }

  const onChangePhone = (value, name) => {
    setState({
      ...state,
      [name]: value,
    })
  }

  useEffect(() => {
    setErrors({
      required: {},
      invalid: {},
    })
  }, [state])

  return {
    state,
    errors,
    setErrors,
    setState,
    onChange,
    onChangeCheckBox,
    toggleValue,
    onChangePhone,
  }
}
export default useForm
