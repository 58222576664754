import React from "react"
import Spinner from "../assets/icons/spinner"

const stylingObject = {
  base: ["button"],
  color: {
    primary: ["primaryBg", "white", "hoverPrimaryBtn", "btn-shadow"],
    secondary: ["secondaryBg", "white", "btn-shadow"],
    white: ["whiteBg", "primary", "btn-shadow"],
  },
  disabled: ["greyBg", "white", "cursor-not-allowed"],
  grow: ["flex-grow"],
}

const Button = ({
  color = "primary",
  disabled,
  grow,
  loading,
  children,
  className = "",
  type = "button",
  ...props
}) => {
  const isDisabled = disabled || loading

  const colorClasses = isDisabled
    ? stylingObject.disabled
    : stylingObject.color[color]

  const growClasses = grow ? stylingObject.grow : []

  const classes = [
    ...stylingObject.base,
    ...colorClasses,
    ...growClasses,
    typeof className === "string" ? className : "",
  ]

  return (
    <button
      {...props}
      type={type}
      className={classes.join(" ")}
      disabled={isDisabled}
    >
      {loading && <Spinner />}

      {children}
    </button>
  )
}

export default Button
