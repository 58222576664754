import React, { useEffect } from "react"
import { connect } from "react-redux"
import {
  getAmenitiesAction,
  getPropertyTypesAction,
  getSizeUnitsAction
} from "../actions/dataActions"

const MainApp = ({
  children,
  lang,
  getPropertyTypesAction,
  getAmenitiesAction,
  getSizeUnitsAction
}) => {
  useEffect(() => {
    getPropertyTypesAction()
    getAmenitiesAction()
    getSizeUnitsAction()
  }, [lang, getPropertyTypesAction, getAmenitiesAction, getSizeUnitsAction])
  return <>{children}</>
}

const mapStateToProps = ({ settings: { lang } }) => ({ lang })
export default connect(mapStateToProps, {
  getPropertyTypesAction,
  getAmenitiesAction,
  getSizeUnitsAction
})(MainApp)
